import React from 'react'
import CategoryCard from '../components/CategoryCard'
import dudImage from '../images/categories/dud.jpeg'
import poznerImage from '../images/categories/pozner.jpeg'
import firstDateImage from '../images/categories/first-date.jpeg'
import aboutLoveImage from '../images/categories/about-love.jpeg'
import aboutIntimacyImage from '../images/categories/about-intimacy.jpeg'
import aboutPartnerImage from '../images/categories/about-partner.jpeg'
import aboutMarriageImage from '../images/categories/about-marriage.jpeg'
import sparkFunEnImage from '../images/categories/spark-fun-en.jpeg'
import hobbiesImage from '../images/categories/hobbies.jpeg'
import sportImage from '../images/categories/sport.jpeg'
import booksImage from '../images/categories/books.jpeg'
import musicImage from '../images/categories/music.jpeg'
import moviesImage from '../images/categories/movies.jpeg'
import makarenaImage from '../images/categories/makarena.jpeg'
import russkieNormImage from '../images/categories/russkie-norm.jpeg'
import aPogovoritImage from '../images/categories/a-pogovorit.jpeg'
import gurievImage from '../images/categories/guriev.jpeg'
import interviewImage from '../images/categories/interview.jpeg'
import careerImage from '../images/categories/career.jpeg'
import workImage from '../images/categories/work.jpeg'

const blogger = {
  dud: <CategoryCard title="Дудь" sampleQuestion="Сколько. Ты. Зарабатываешь?" category="dud" image={dudImage} />,
  aPogovorit: (
    <CategoryCard
      title="А поговорить?"
      sampleQuestion="Пластические операции. Больше - лучше?"
      category="a-pogovorit"
      image={aPogovoritImage}
    />
  ),
  pozner: (
    <CategoryCard
      title="Познер"
      sampleQuestion="Оказавшись перед Богом, что Вы ему скажете?"
      category="pozner"
      image={poznerImage}
    />
  ),
  makarena: (
    <CategoryCard
      title="Макарена"
      sampleQuestion="Три твоих любимых бренда одежды?"
      category="makarena"
      image={makarenaImage}
    />
  ),
  russkieNorm: (
    <CategoryCard
      title="Русские норм!"
      sampleQuestion="Зачем успешному человеку Тони Роббинс?"
      category="russkie-norm"
      image={russkieNormImage}
    />
  ),
  guriev: (
    <CategoryCard
      title="Гуриев"
      sampleQuestion="У каких стран России полезно учиться и чему?"
      category="guriev"
      image={gurievImage}
    />
  ),
}

const english = {
  sparkFunEn: (
    <CategoryCard
      title="Spark fun"
      sampleQuestion="If you had a theme song, what would it be?"
      category="spark-fun-en"
      image={sparkFunEnImage}
    />
  ),
}

const relationship = {
  firstDate: (
    <CategoryCard
      title="Первое свидание"
      sampleQuestion="Ты считаешь себя романтиком?"
      category="first-date"
      image={firstDateImage}
    />
  ),
  aboutLove: (
    <CategoryCard
      title="О любви"
      sampleQuestion="Вы верите в любовь с первого взгляда?"
      category="about-love"
      image={aboutLoveImage}
    />
  ),
  aboutPartner: (
    <CategoryCard
      title="О партнере"
      sampleQuestion="Кто умнее - Вы или Ваш партнер?"
      category="about-partner"
      image={aboutPartnerImage}
    />
  ),
  aboutSex: (
    <CategoryCard
      title="Об интимной близости"
      sampleQuestion="Сколько свободы Вам необходимо в отношениях?"
      category="about-intimacy"
      image={aboutIntimacyImage}
    />
  ),
  aboutMarriage: (
    <CategoryCard
      title="О свадьбе и браке"
      sampleQuestion="Что самое лучшее есть в браке?"
      category="about-marriage"
      image={aboutMarriageImage}
    />
  ),
}

const entertainment = {
  hobbies: (
    <CategoryCard
      title="Про развлечения и свободное время"
      sampleQuestion="Вы хотели бы поучаствовать в каком-нибудь ток-шоу?"
      category="hobbies"
      image={hobbiesImage}
    />
  ),
  sport: <CategoryCard title="Про спорт" sampleQuestion="Роналду или Месси?" category="sport" image={sportImage} />,
  music: (
    <CategoryCard
      title="Про музыку"
      sampleQuestion="Назовите самую нелепую, на Ваш взгляд, песню?"
      category="music"
      image={musicImage}
    />
  ),
  books: (
    <CategoryCard
      title="Про книги"
      sampleQuestion="Если Вы напишете книгу, кому Вы ее посвятите?"
      category="books"
      image={booksImage}
    />
  ),
  movies: (
    <CategoryCard
      title="Про кино"
      sampleQuestion="О чем бы Вы хотели снять фильм?"
      category="movies"
      image={moviesImage}
    />
  ),
}

const work = {
  interview: (
    <CategoryCard
      title="Собеседование"
      sampleQuestion="Ваше самое большое профессиональное достижение?"
      category="interview"
      image={interviewImage}
    />
  ),
  work: (
    <CategoryCard
      title="Про работу"
      sampleQuestion="Самый нелепый совет по работе, который тебе давали?"
      category="work"
      image={workImage}
    />
  ),
  career: (
    <CategoryCard
      title="Про карьеру"
      sampleQuestion="Что ты можешь сделать, чтобы стать лучшим в своем деле?"
      category="career"
      image={careerImage}
    />
  ),
}

export { blogger, english, relationship, entertainment, work }
